import moment from 'moment'

const slugify = string => {
  return string
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

const stringify = slug => {
  return slug.replace('-', ' ').toUpperCase()
}

const formatTimestamp = timestamp => {
  return moment(timestamp.toDate()).format('MMM Do YYYY, h:mm a')
}

const firstLetterCaps = string => {
  return string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export { slugify, stringify, formatTimestamp, firstLetterCaps }
